
import './App.css';
import Navbar from './components/Navbar'
import Products from './components/Products'

function App() {

  return (
    <div >
        <div className=''>
            <Navbar/>
            <Products/> 
        </div>
    </div>
  );
}

export default App;
